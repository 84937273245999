import { Navigate } from 'react-router-dom';
import { lazy } from 'react';

/****Layouts*****/
const FullLayout = lazy(() => import('../layouts/FullLayout.js'));

/***** Pages ****/
const Dashboard = lazy(() => import('../views/Dashboard.js'));
const Service = lazy(() => import('../views/ui/Service'));
const Batch = lazy(() => import('../views/ui/Batch'));
const Scrap = lazy(() => import('../views/ui/Scrap'));
const UserVariation = lazy(() => import('../views/UserVari.js'));
const UserInfo = lazy(() => import('../views/UserInfo.js'));
const LoginInfo = lazy(() => import('../views/LoginInfo.js'));
const Code = lazy(() => import('../views/ui/Code'));
const Error = lazy(() => import('../views/ui/Error'));
const CleanService = lazy(() => import('../views/CleanService'));
const CleanDetail = lazy(() => import('../components/detail/CleanDetail.js'));
const SampleRequest = lazy(() => import('../views/SampleRequest'));
const SampleUrl = lazy(() => import('../views/SampleUrl'));
const TaxService = lazy(() => import('../views/TaxService'));
const MatchVendor = lazy(() => import('../components/modal/MatchVendor'));
const SubscribeTerm = lazy(() => import('../components/modal/SubscribeTerm'));
const VendorManage = lazy(() => import('../views/VendorManage'));
const VendorRegister = lazy(() => import('../views/VendorRegister'));
const PayManage = lazy(() => import('../views/PayManage.js'));
const HelthCheck = lazy(() => import('../views/HelthCheck.js'));
//퍼블용 페이지
const PayDetail = lazy(() => import('../views/publishing/PayDetail.js'));
const PayDetailMod = lazy(() => import('../views/publishing/PayDetailMod.js'));
const Clean1 = lazy(() => import('../views/publishing/Clean1.js'));
const Clean2 = lazy(() => import('../views/publishing/Clean2.js'));
const Clean3 = lazy(() => import('../views/publishing/Clean3.js'));
const Clean4 = lazy(() => import('../views/publishing/Clean4.js'));
const CleanDetail1 = lazy(() => import('../views/publishing/CleanDetail1.js'));
const CleanDetail2 = lazy(() => import('../views/publishing/CleanDetail2.js'));
const CleanDetail3 = lazy(() => import('../views/publishing/CleanDetail3.js'));
const CleanDetail4 = lazy(() => import('../views/publishing/CleanDetail4.js'));
/*****Routes******/
const ThemeRoutes = [
  {
    exact: true,
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', exact: true, element: <Dashboard /> },
      { path: '/dashboard', exact: true, element: <Dashboard /> },
      { path: '/userInfo', exact: true, element: <UserInfo /> },
      { path: '/loginInfo', exact: true, element: <LoginInfo /> },
      { path: '/userVaration', exact: true, element: <UserVariation /> },
      { path: '/service', exact: true, element: <Service /> },
      { path: '/batch', exact: true, element: <Batch /> },
      { path: '/scrap', exact: true, element: <Scrap /> },
      { path: '/code', exact: true, element: <Code /> },
      { path: '/cleanService', exact: true, element: <CleanService /> },
      { path: '/cleanDetail', exact: true, element: <CleanDetail /> },
      { path: '/sampleRequest', exact: true, element: <SampleRequest /> },
      { path: '/sampleUrl', exact: true, element: <SampleUrl /> },
      { path: '/taxService', exact: true, element: <TaxService /> },
      { path: '/matchVendor', exact: true, element: <MatchVendor /> },
      { path: '/vendorManage', exact: true, element: <VendorManage /> },
      { path: '/vendorRegister', exact: true, element: <VendorRegister /> },
      { path: '/subscribeTerm', exact: true, element: <SubscribeTerm /> },
      { path: '/payManage', exact: true, element: <PayManage /> },
      { path: '/helthCheck', exact: true, element: <HelthCheck /> },
      //퍼블용 페이지
      { path: '/publishing/PayDetail', exact: true, element: <PayDetail /> },
      { path: '/publishing/PayDetailMod', exact: true, element: <PayDetailMod /> },
      { path: '/publishing/Clean1', exact: true, element: <Clean1 /> },
      { path: '/publishing/Clean2', exact: true, element: <Clean2 /> },
      { path: '/publishing/Clean3', exact: true, element: <Clean3 /> },
      { path: '/publishing/Clean4', exact: true, element: <Clean4 /> },
      { path: '/publishing/CleanDetail1', exact: true, element: <CleanDetail1 /> },
      { path: '/publishing/CleanDetail2', exact: true, element: <CleanDetail2 /> },
      { path: '/publishing/CleanDetail3', exact: true, element: <CleanDetail3 /> },
      { path: '/publishing/CleanDetail4', exact: true, element: <CleanDetail4 /> },
    ],
  },
  { path: '/error', exact: true, element: <Error /> },
];

export default ThemeRoutes;
