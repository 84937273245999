import { lazy } from "react";
import { Navigate } from "react-router-dom";




/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));



/***** Pages ****/

const Error = lazy(() => import("../views/ui/Error"));

/*****Routes******/
const ThemeRoutes = [
  {
    exact:true,
    path: "/",
    children: [
      { path: "/*", element: <Navigate to="/error" /> },
      { path: "/error", exact: true, element: <Error /> },
    ],
  },
  
];

export default ThemeRoutes;
