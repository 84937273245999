// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fallback-spinner {
  position: relative;
  display: flex;
  height: 100vh;
  width: 100%; }

.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 3px solid transparent; }
`, "",{"version":3,"sources":["webpack://./src/layouts/loader/loader.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,WAAW,EAAA;;AAEb;EACE,kBAAkB;EAClB,sBAAsB;EACtB,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,kBAAkB;EAElB,sBAAsB;EACtB,6BAA6B,EAAA","sourcesContent":[".fallback-spinner {\r\n  position: relative;\r\n  display: flex;\r\n  height: 100vh;\r\n  width: 100%;\r\n}\r\n.loading {\r\n  position: absolute;\r\n  left: calc(50% - 35px);\r\n  top: 50%;\r\n  width: 55px;\r\n  height: 55px;\r\n  border-radius: 50%;\r\n  -webkit-box-sizing: border-box;\r\n  box-sizing: border-box;\r\n  border: 3px solid transparent;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
